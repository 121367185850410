import { graphql, Link } from 'gatsby';
import React, { Fragment, useState } from 'react';

import { ListArticlesAndClubsQuery } from '../../graphql-types';
import {
	CentralTextBlock,
	Context,
	Hero,
	MetaData,
	TitleSection,
} from '../components';
import ArticlesFilter from '../components/ArticlesFilter';
import { FilterKeys } from '../components/ArticlesFilter/ArticlesFilter';
import Button from '../components/Button';
import ContentCard from '../components/ContentCard';
import ThreeCol from '../components/ThreeCol';

type Props = {
	data: ListArticlesAndClubsQuery;
	context?: {
		interest?: string;
	};
	location?: { pathname?: string };
};

const Articles = ({ data, context, location }: Props) => {
	const [filterKey, setFilterKey] = useState<FilterKeys>();

	const acf = data.clubs?.acf;
	const articles = data.articles.nodes;
	const interest = location?.pathname?.split?.('/').pop() ?? '';

	return (
		<Context.Consumer>
			{({ setModal }) => {
				return (
					<Fragment>
						<MetaData
							title={acf?.meta_title ?? data.clubs?.title ?? ''}
							description={acf?.meta_description ?? ''}
						/>

						<Hero
							images={acf?.clubs_header_images}
							title={acf?.clubs_header_title}
							logo={acf?.clubs_header_logo && acf?.clubs_header_logo.source_url}
							link={acf?.clubs_header_link}
							linkText={acf?.clubs_header_link_text}
							linkPopup={acf?.clubs_header_link_popup}
						/>

						{acf?.grey_box?.link_text && (
							<CentralTextBlock
								title={acf?.grey_box.title}
								text={acf?.grey_box.text}
								linkPopup={acf?.grey_box.link_popup}
								link={acf?.grey_box.link}
								linkText={acf?.grey_box.link_text}
								after={
									<div className="button-wrapper-5-to-9">
										{acf.grey_box.link_popup ? (
											<Button onClick={() => setModal('Find out more')}>
												{acf.grey_box.link_text}
											</Button>
										) : acf?.grey_box?.link?.slice?.(0, 4) === 'http' ? (
											<Button href={acf?.grey_box.link ?? ''} target={'_blank'}>
												{acf.grey_box.link_text}
											</Button>
										) : (
											<Link
												to={acf?.grey_box.link ?? ''}
												className="w-button w-button--inverted"
											>
												{acf.grey_box.link_text}
											</Link>
										)}
									</div>
								}
							/>
						)}

						<TitleSection
							title={''}
							content={
								<>
									<ArticlesFilter
										onSelect={setFilterKey}
										// @ts-expect-error
										selectedKey={interest}
									/>
									<ThreeCol>
										{articles.map((article) => {
											return (
												<ContentCard
													readTime={article.read_time ?? 0}
													date={article.date}
													link={`/articles/${article?.slug}` ?? ''}
													text={article?.excerpt ?? ''}
													image={article?.images?.banner ?? ''}
													title={article?.title ?? ''}
												/>
											);
										})}
									</ThreeCol>
								</>
							}
						/>
					</Fragment>
				);
			}}
		</Context.Consumer>
	);
};

export const query = graphql`
	query ListFilteredArticles($interest: String) {
		articles: allStartupClubArticles(
			filter: { interests: { elemMatch: { slug: { eq: $interest } } } }
			sort: { order: DESC, fields: date }
		) {
			nodes {
				title
				images {
					banner
				}
				excerpt
				date(fromNow: true)
				read_time
				slug
				interests {
					slug
				}
			}
		}
		clubs: wordpressPage(slug: { eq: "clubs" }) {
			title
			acf {
				clubs_header_title
				clubs_header_images {
					image {
						source_url
					}
					focus
				}
				clubs_header_logo {
					source_url
				}
				clubs_header_link_popup
				clubs_header_link
				clubs_header_link_text
				grey_box {
					title
					text
					link
					link_text
					link_popup
				}
				events_title
				clubs_content_title
				clubs_content {
					title
					link
					image {
						source_url
					}
				}
				meta_title
				meta_description
			}
		}
	}
`;
export default Articles;
